<template>
  <div class="page-content">
    <div class="container-fluid">
      <DataTable
        :refs="refs"
        :colspan="5"
        :hasSearchFilter="true"
        :hasDateFilter="true"
        :hasStatusFilter="true"
        :hasHashId="true"
        :hasMultiSelect="false"
        :hasBulkAction="true"
        :hasExport="true"
        :addNew="addNew"
        :hasShowEntries="true"
        :lblSearch="$t('app.search')"
        :fields="{
          id: 'id',
          title: 'title',
          date: 'date',
          status: 'status',
        }"
        :hasImage="false"
        :hasTitle="true"
        :title="$t('view.paymentMethod_title')"
        :hasStatus="true"
        :hasActions="true"
        :hasDeleteButton="true"
        :actionable="true"
        :sorDataTable="true"
      >
      </DataTable>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DataTable from "@/components/DataTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "List",
  components: {
    DataTable,
  },
  data() {
    return {
      //
      addNew: true,
      refs: "payment-methods",
    };
  },
  watch: {
    //
  },
  mounted() {
    //
  },
  created() {
    setCurrentPageTitle(this.$t("nav.paymentMethods"), "bx bx-money");
  },
  methods: {},
});
</script>
